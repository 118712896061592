import React, { useEffect, useState } from 'react'
import './index.css'
import { useParams } from "react-router-dom";
import Function from '../function'

const Index = () => {
    let {id} = useParams();
    const [List,setList] = useState([]);
    const [details, setDetails] = useState({
        id: 0,
        address1:"",
        address2:"",
        city:"",
        country:"",
        day:"",
        month:"",
        year:"",
        email:"",
        firstname:"",
        image:"",
        lastname:"",
        phone:"",
        postalcode:"",
        referrerid:"",
        state:"",
        username:"",
        totalpoints:0
    });
    useEffect(()=>{
        Function.GetSingle(id,setDetails);
        Function.GetHistory(id,setList);
    },[id])
  return (
    <div className='admin_member_item_container'>
      <div className='admin_member_item_details'>
        <div className='admin_members_table'>
            <div className='admin_members_row'>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Id</p>
                </div>
                <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                    <p>{details.id}</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Points</p>
                </div>
                <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                    <p>{details.totalpoints}</p>
                </div>
            </div>
            <div className='admin_members_row'>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Surname</p>
                </div>
                <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                    <p>{details.lastname}</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>First name</p>
                </div>
                <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                    <p>{details.firstname}</p>
                </div>
            </div>
            <div className='admin_members_row'>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Date of birth</p>
                </div>
                <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                    <p>{details.month}/{details.day}/{details.year}</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Phone</p>
                </div>
                <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                    <p>{details.phone}</p>
                </div>
            </div>
            <div className='admin_members_row'>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Email</p>
                </div>
                <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                    <p>{details.email}</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Address</p>
                </div>
                <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                    <p>{details.address1} {details.address2} {details.city} {details.state} {details.country} {details.postalcode}</p>
                </div>
            </div>
        </div>
      </div>
      <div className='admin_member_item_history'>
        <h3>Points History</h3>
        <div className='admin_members_table'>
            <div className='admin_members_row'>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Date</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"30%"}}>
                    <p>Points</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"30%"}}>
                    <p>Channel</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Expiration date</p>
                </div>
            </div>
            {List.length > 0 &&
                List.map((item,index)=>{
                    return(
                        <div className='admin_members_row' key={index}>
                            <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                                <p>{item.date.slice(0,10)}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                                <p>{item.points}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                                <p>{item.channel}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                                <p>{item.edate.slice(0,10)}</p>
                            </div>
                        </div>
                    )
                })
            }
            {List.length == 0 &&
                <div className='admin_members_row'>
                    <div className='admin_members_col admin_members_td' style={{width:"100%"}}>
                        <p style={{textAlign:"center"}}>No data found</p>
                    </div>
                </div>
            }
        </div>
      </div>
    </div>
  )
}

export default Index
