import React from 'react'
import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import './index.css'
import Logo from '../../images/logo.webp'

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    if(!User){
        return <Navigate to={"/"}/>
    }
    else{
        if(User.role !== 'admin'){
            return <Navigate to={"/"}/>
        }
    }
    const Urls = [
        { url:"/admin/dashboard", slug:"dashboard", name:"Dashboard" },
        { url:"/admin/members", slug:"members", name:"Members" },
        { url:"/admin/pointsettings", slug:"pointsettings", name:"Point settings" },
        { url:"/admin/systemsettings", slug:"systemsettings", name:"Settings" },
        { url:"/signout", slug:"signout", name:"Signout" }
    ]
    return (
        <div className='admin_layout_wrapper'>
            <div className='admin_layout_container'>
                <div className='admin_layout_topbar'>
                    <div className='admin_layout_logo'>
                        <img src={Logo}/>
                    </div>
                    <div className='admin_layout_urls'>
                        {
                            Urls.map((item,index)=>{
                                return(
                                    <div key={index} className={window.location.href.includes(item.slug)? 'admin_layout_url admin_layout_url_active':'admin_layout_url'}>
                                        <a href={item.url}>{item.name}</a>
                                        <div className='admin_layout_url_bar_container'>
                                            <div className='admin_layout_url_bar'></div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='admin_layout_body'>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default Index
