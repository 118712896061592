import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = async (setValues)=>{
    let res = await fetch(ApiUrl.Admin_Users_Members, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    if(res.status > 400 && res.status < 410){
        localStorage.removeItem('user-info');
        document.location.replace('/');
    }
    res = await res.json();
    if(res.success){
        setValues(res.response);
    }
    else{
        alert(res.response);
    }
}

const GetSingle = async (id,setValues)=>{
    let res = await fetch(ApiUrl.Admin_Users_Members+"/"+id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    if(res.status > 400 && res.status < 410){
        localStorage.removeItem('user-info');
        document.location.replace('/');
    }
    res = await res.json();
    if(res.success){
        setValues(res.response);
    }
    else{
        alert(res.response);
    }
}

const GetHistory = async (id,setValues)=>{
    let res = await fetch(ApiUrl.Admin_Pointshistory+"/"+id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    if(res.status > 400 && res.status < 410){
        localStorage.removeItem('user-info');
        document.location.replace('/');
    }
    res = await res.json();
    if(res.success){
        setValues(res.response);
    }
    else{
        alert(res.response);
    }
}

const SetPoints = async (id,points)=>{
    let res = await fetch(ApiUrl.Admin_Users_Members+"/"+id+'/updatepoints', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify({points:points})
    })

    res = await res.json();

    if(res.success){
        return true;
    }
    else{
        alert(res.response);
        return false;
    }
}

const Delete = async (id)=>{
    let res = await fetch(ApiUrl.Admin_Users_Members+"/"+id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })

    res = await res.json();

    if(res.success){
        return true;
    }
    else{
        alert(res.response);
        return false;
    }
}

const exportFunctions = {Get,GetSingle,GetHistory, SetPoints, Delete}

export default exportFunctions