import React from 'react'
import { Outlet } from 'react-router-dom'
import './index.css'

const Index = () => {
  return (
    <div className='admin_pointsettings_layout_wrapper'>
      <div className='admin_pointsettings_layout_container'>
        <div className='admin_pointsettings_layout_row'>
            <div className='admin_pointsettings_layout_col250'>
                <div className='admin_pointsettings_layout_nav_container'>
                    <div className={ window.location.href.includes("pointsettings/general")? 'admin_pointsettings_layout_nav_item_active admin_pointsettings_layout_nav_item' : 'admin_pointsettings_layout_nav_item'}>
                        <a href='/admin/pointsettings/general'>General</a>
                        <div className='admin_pointsettings_layout_nav_item_bar_container'>
                            <div className='admin_pointsettings_layout_nav_item_bar'></div>
                        </div>
                    </div>
                    <div className={ window.location.href.includes("pointsettings/activity")? 'admin_pointsettings_layout_nav_item_active admin_pointsettings_layout_nav_item' : 'admin_pointsettings_layout_nav_item'}>
                        <a href='/admin/pointsettings/activity'>Activity</a>
                        <div className='admin_pointsettings_layout_nav_item_bar_container'>
                            <div className='admin_pointsettings_layout_nav_item_bar'></div>
                        </div>
                    </div>
                    <div className={ window.location.href.includes("pointsettings/exchange")? 'admin_pointsettings_layout_nav_item_active admin_pointsettings_layout_nav_item' : 'admin_pointsettings_layout_nav_item'}>
                        <a href='/admin/pointsettings/exchange'>Exchange</a>
                        <div className='admin_pointsettings_layout_nav_item_bar_container'>
                            <div className='admin_pointsettings_layout_nav_item_bar'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='admin_pointsettings_layout_col100'>
                <div className='admin_pointsettings_layout_body'>
                    <Outlet />
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Index
