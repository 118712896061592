import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Functions from "./function";
import ApiUrl from "../../../configTools/api";
import "./index.css";
import imagesLoaded from "imagesloaded";

const Index = () => {
  const [searchParams] = useSearchParams();
  const cardRef = useRef(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    Functions.Get(
      parseInt(searchParams.get("id")),
      (databack) => {
        setData(databack);
      },
      searchParams.get("prints")
    );
  }, [searchParams.get("id"), searchParams.get("prints")]);

  useEffect(() => {
    imagesLoaded(cardRef.current, { background: true }, () => {
      window.print();

      window.onafterprint = () => {
        window.close();
      };
    });
  }, [data]);
  return (
    <>
      {data && (
        <table ref={cardRef}>
          <tbody>
            {data.codes.map((code, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="admin_points_activity_card_display_wrapper_p">
                      <div className="admin_points_activity_card_display_container">
                        <div
                          className="points_card_backgound"
                          style={{
                            backgroundImage: `url('${
                              ApiUrl.Image_URL + data.bgimage
                            }')`,
                          }}
                        >
                          <div className="points_card_row">
                            <div className="points_card_col">
                              <div
                                className="points_card_logo"
                                style={{
                                  backgroundImage: `url('${
                                    ApiUrl.Image_URL + data.logo
                                  }')`,
                                }}
                              ></div>
                            </div>
                            <div className="points_card_col">
                              <div className="points_card_text">
                                <h6>{data.name}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="points_card_spacer"></div>
                          <div className="points_card_row">
                            <div className="points_card_col">
                              <div className="points_card_qrcode_container">
                                {code.code}
                              </div>
                            </div>
                            <div className="points_card_col">
                              <div className="points_card_details">
                                <p>EXP {data.edate.slice(0, 10)}</p>
                                <h5>{data.points} Points</h5>
                                <p>Points Card</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default Index;
