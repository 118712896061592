import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const CheckPassword = async (password,next)=>{
    let res = await fetch(ApiUrl.OAuth_Login, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: User.username,
            password: password
        })
    })
    res = await res.json();
    if(res.success){
        next(true);
    }
    else{
        next(false);
    }
}

const UpdatePassword = async (password,next)=>{
    let res = await fetch(ApiUrl.OAuth_UserInfo+"/changepassword/"+User.id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify({password:password})
    })
    res = await res.json();
    if(res.success){
        next(true);
    }
    else{
        next(false);
    }
}

export default {CheckPassword,UpdatePassword}