import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import NumberInputPopup from '../../../../components/numberInputPopup'
import Functions from './function'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
    const [pop,setPop]= useState(false);
    const [poped,setPopED]= useState(false);
    const [id,setID]= useState(1001);
    const [List,setList]= useState([]);
    useEffect(()=>{
        Functions.Get(setList);
    },[])
  return (
    <div className='admin_points_general_container'>
      <div className='admin_members_table'>
          <div className='admin_members_row'>
              <div className='admin_members_col admin_members_th' style={{width:"30%"}}>
                  <p>Name</p>
              </div>
              <div className='admin_members_col admin_members_th' style={{width:"40%"}}>
                  <p>Expiration date</p>
              </div>
              <div className='admin_members_col admin_members_th' style={{width:"30%"}}>
                  <p>Points</p>
              </div>
          </div>
          {
              List.map((item,index)=>{
                  return(
                      <div className='admin_members_row' key={index}>
                          <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                              <p>{item.name}</p>
                          </div>
                          <div className='admin_members_col admin_members_td' style={{width:"40%"}}>
                              <p><FontAwesomeIcon icon={faPenToSquare} style={{cursor:"pointer"}} onClick={()=>{
                                setPopED(true);
                                setID(item.id);
                              }}/> {item.edate}</p>
                          </div>
                          <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                              <p><FontAwesomeIcon icon={faPenToSquare} style={{cursor:"pointer"}} onClick={()=>{
                                setPop(true);
                                setID(item.id);
                              }}/> {item.points}</p>
                          </div>
                      </div>
                  )
              })
          }
      </div>
      {pop &&
        <NumberInputPopup setPop={setPop} Function={(cbvalue)=>{
            for (let index = 0; index < List.length; index++) {
                const element = List[index];
                if(element.id == id){
                    Functions.Put(toast,id,{
                        name: element.name,
                        points: cbvalue,
                        edate: element.edate,
                        date: element.date
                    },setList);
                    setPop(false);
                }
            }
        }}/>
      }
      {poped &&
        <NumberInputPopup setPop={setPopED} Function={(cbvalue)=>{
            for (let index = 0; index < List.length; index++) {
                const element = List[index];
                if(element.id == id){
                    Functions.Put(toast,id,{
                        name: element.name,
                        points: element.points,
                        edate: cbvalue,
                        date: element.date
                    },setList);
                    setPopED(false);
                }
            }
        }}/>
      }
      <ToastContainer />
    </div>
  )
}

export default Index
