import React, { useEffect } from 'react'

const Signout = () => {
    useEffect(()=>{
        localStorage.removeItem('user-info');
        document.location.replace('/');
    },[])
  return (
    <div>
      
    </div>
  )
}

export default Signout
