import {BrowserRouter, Routes, Route,Navigate, Outlet} from 'react-router-dom';
import './App.css'
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './configTools/scrollTop';
import PublicLayout from './layout/public'
import PublicLogin from './pages/public/login'
import PublicSignup from './pages/public/signup'
import PublicExtraUser from './pages/public/extrauser'
import PublicShopifySuccess from './pages/public/shopifysuccess'
import PublicShopifyFailed from './pages/public/shopifyfailed'

import AdminLayout from './layout/admin'

import AdminDashboard from './pages/admin/dashboard'

import AdminMembers from './pages/admin/members'
import AdminMember from './pages/admin/members/member'

import AdminSocial from './pages/admin/social'

import AdminPointsettingsLayout from './layout/admin/pointsettings'
import AdminPointGeneral from './pages/admin/pointsettings/generalpoints'
import AdminPointActivity from './pages/admin/pointsettings/activitypoints'
import AdminPointActivityCard from './pages/admin/pointsettings/activitypoints/card'
import AdminPointExchange from './pages/admin/pointsettings/exchangerate'

import AdminSettings from './pages/admin/settings'

import AdminPrintLayout from './layout/adminprint'
import AdminPrintCards from './pages/adminprint/cards'

import Notfound from './pages/public/notfound'
import Signout from './pages/signout';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<PublicLayout/>}>
            <Route index element={<PublicLogin/>} />
            <Route path='shopifymessagesuccess' element={<PublicShopifySuccess/>}/>
            <Route path='shopifymessagefailed' element={<PublicShopifyFailed/>}/>
            <Route path='*' element={<Notfound/>}/>
            <Route path='extrauser' element={<PublicExtraUser/>}/>
          </Route>
          <Route path='/admin' element={<AdminLayout/>}>
            <Route index element={<Navigate to='/admin/dashboard'/>} />
            <Route path='dashboard' element={<AdminDashboard/>} />
            <Route path='members' element={<AdminMembers/>}/>
            <Route path='members/:id' element={<AdminMember/>}/>
            <Route path='social' element={<AdminSocial/>}/>
            <Route path='pointsettings' element={<AdminPointsettingsLayout/>}>
              <Route index element={<Navigate to='/admin/pointsettings/general'/>} />
              <Route path='general' element={<AdminPointGeneral/>}/>
              <Route path='activity' element={<Outlet/>}>
                <Route index element={<AdminPointActivity/>}/>
                <Route path='card/:id' element={<AdminPointActivityCard/>}/>
              </Route>
              <Route path='exchange' element={<AdminPointExchange/>}/>
            </Route>
            <Route path='systemsettings' element={<AdminSettings/>}/>
          </Route>
          <Route path='/adminprints' element={<AdminPrintLayout/>}>
            <Route path='cards' element={<AdminPrintCards/>} />
          </Route>
          <Route path='/signout' element={<Signout/>}/>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
