import React from 'react'
import './index.css'

const Index = ({Value, Action}) => {
  return (
    <div className='airiosoft_button'>
      <button onClick={Action}>{Value}</button>
    </div>
  )
}

export default Index
