import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

const Body = ()=>{
    return(
        <div>
            <Outlet/>
        </div>
    )
}

const Index = () => {
    const User = JSON.parse(localStorage.getItem("user-info"));
    if(!User){
        return <Navigate to={"/"}/>
    }
    else{
        if(User.role !== 'admin'){
            return <Navigate to={"/"}/>
        }
    }
  return (
    <Body/>
  )
}

export default Index
