import React, { useEffect, useState } from 'react'
import './index.css'
import Functions from './function'
import Button from '../../../components/button'
import ButtonAlt from '../../../components/buttonalt'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
    const [List,setList] = useState([]);
    useEffect(()=>{
        Functions.Get(setList);
    },[])
  return (
    <div className='airiosoft_admin_social'>
        <h3>Pending requests</h3>
        <div className='admin_members_table'>
            <div className='admin_members_row'>
                <div className='admin_members_col admin_members_th' style={{width:"5%"}}>
                    <p>Id</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"5%"}}>
                    <p>User</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"15%"}}>
                    <p>Title</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"55%"}}>
                    <p>Description</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                </div>
            </div>
            {List.length > 0 &&
                List.map((item,index)=>{
                    return(
                        <div className='admin_members_row' key={index}>
                            <div className='admin_members_col admin_members_td' style={{width:"5%"}}>
                                <p>{item.id}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"5%"}}>
                                <p><a href={'/admin/members/'+item.userid}>{item.userid}</a></p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"15%"}}>
                                <p>{item.title}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"55%"}}>
                                <p>{item.description}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                                <div style={{display:"flex",justifyContent:"flex-end"}}>
                                    <div style={{marginRight:"10px",marginLeft:"5px"}}>
                                        <Button Value={'Approve'} Action={()=>{
                                            Functions.Put(toast,item.id,{utype:1},setList)
                                        }}/>
                                    </div>
                                    <div style={{marginRight:"10px",marginLeft:"5px"}}>
                                        <ButtonAlt Value={'Reject'} Action={()=>{
                                            Functions.Put(toast,item.id,{utype:2},setList)
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {List.length == 0 &&
                <div className='admin_members_row'>
                    <div className='admin_members_col admin_members_td' style={{width:"100%"}}>
                        <p style={{textAlign:"center"}}>No data found</p>
                    </div>
                </div>
            }
        </div>
        <ToastContainer/>
    </div>
  )
}

export default Index
