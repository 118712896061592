import React from 'react'
import './index.css'

const Index = ({Lable,Placeholder,Type,Value,setValue,readonly,isImportant}) => {
  return (
    <div className='airiosoft_input_container'>
      <label>{Lable}{isImportant &&
        <span>*</span>
      }</label>
      <input 
        type={Type}
        placeholder={Placeholder} 
        value={Value} 
        onChange={(e)=>setValue(e.target.value)}
        readOnly={readonly}
      />
    </div>
  )
}

export default Index
