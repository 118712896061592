import React from 'react'
import Button from '../../../components/button'
import './index.css'

const Index = () => {
  return (
    <div className='extra_user_form_wrapper'>
      <div className='extra_user_form_container'>
        <h3>User is not admin "Only admin are allowed on this application"</h3>
        <Button Action={()=>{
            window.location.replace('/signout')
        }} Value={'Sign out'}/>
      </div>
    </div>
  )
}

export default Index
