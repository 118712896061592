const BaseURL = '';//'/api/'//'https://inventory.skymartvip.com/api/'//'/api/';//'http://localhost:4000/api/';

const ApiUrl = {
    OAuth_Login: BaseURL+'/api/oauth/login',
    OAuth_Signup: BaseURL+'/api/oauth/signup',
    OAuth_UserInfo: BaseURL+'/api/oauth/userinfo',
    Admin_Dashboard: BaseURL+'/api/admin/dashboard',
    Admin_Pointsrate: BaseURL+'/api/admin/pointsrate',
    Admin_Pointsdata: BaseURL+'/api/admin/pointsdata',
    Admin_Pointshistory: BaseURL+'/api/admin/pointshistory',
    Admin_Users_Members: BaseURL+'/api/admin/users/members',
    Admin_Cards: BaseURL+'/api/admin/cards',
    Admin_Share: BaseURL+'/api/admin/share',
    Admin_Qrcodes: BaseURL+'/api/admin/qrcodes',
    Admin_Images: BaseURL+'/api/admin/images',
    Image_URL: BaseURL+'/img/'
}

export default ApiUrl