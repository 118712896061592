import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import Input from '../../../../../components/input'
import Button from '../../../../../components/button'
import Functions from '../function'

const Index = ({setPop,toast,setValues}) => {
    const [Name, setName] = useState('');
    const [Points, setPoints] = useState(0);
    const [EDate, setEDate] = useState(0);
  return (
    <div className='airiosoft_nip_popup_wrapper'>
      <div className='airiosoft_nip_popup_container'>
        <div className='airiosoft_nip_popup_cross'>
            <button onClick={()=>{
                setPop(false);
            }}><FontAwesomeIcon icon={faXmark}/></button>
        </div>
        <div className='airiosoft_nip_popup_form'>
            <Input Lable={'Name'} Placeholder={'Enter name'} Type={'text'} Value={Name} setValue={setName} readonly={false}/>
            <Input Lable={'Points'} Placeholder={'Enter points'} Type={'number'} Value={Points} setValue={setPoints} readonly={false}/>
            <Input Lable={'Expire days'} Placeholder={'Enter expire days'} Type={'date'} Value={EDate} setValue={setEDate} readonly={false}/>
            <Button Value={'Save'} Action={()=>{
                Functions.Post(toast,{
                    name: Name,
                    points: Points,
                    edate: EDate
                },setValues);
                setPop(false)
            }}/>
        </div>
      </div>
    </div>
  )
}

export default Index
