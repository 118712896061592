import ApiUrl from '../../../configTools/api'
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = async (id,setValues,limit = 50)=>{
    let res = await fetch(ApiUrl.Admin_Cards+"/"+id + "?limit=" + limit, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    if(res.status > 400 && res.status < 410){
        localStorage.removeItem('user-info');
        document.location.replace('/');
    }
    res = await res.json();
    if(res.success){
        setValues(res.response);
    }
    else{
        alert(res.response);
    }
}

const GetQRS = async (body,setValues)=>{
    let res = await fetch(ApiUrl.Admin_Qrcodes, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    })
    if(res.status > 400 && res.status < 410){
        localStorage.removeItem('user-info');
        document.location.replace('/');
    }
    res = await res.json();
    if(res.success){
        setValues(res.ids);
    }
    else{
        console.log(res.response);
    }
}

export default {Get,GetQRS}