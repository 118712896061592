import ApiUrl from "../../../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const UploadImage = async (e, next) => {
  if (e.target.files && e.target.files[0]) {
    if (e.target.files[0].size > 1097152) {
      alert("File is too big!");
      next(false);
      return;
    }
    let imageFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", imageFile);
    fetch(ApiUrl.Admin_Images, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + User.token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          next({ status: true, image: data.response });
        } else {
          next({ status: false, image: "" });
          console.log(data.response);
        }
      });
  }
};

const Get = async (id, setValues, limit = 50) => {
  let res = await fetch(ApiUrl.Admin_Cards + "/" + id + "?limit=" + limit, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + User.token,
    },
  });
  if (res.status > 400 && res.status < 410) {
    localStorage.removeItem("user-info");
    document.location.replace("/");
  }
  res = await res.json();
  if (res.success) {
    setValues(res.response);
  } else {
    alert(res.response);
  }
};

const Put = async (toast, toastid, id, body, setValues) => {
  let res = await fetch(ApiUrl.Admin_Cards + "/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + User.token,
    },
    body: JSON.stringify(body),
  });
  if (res.status > 400 && res.status < 410) {
    localStorage.removeItem("user-info");
    document.location.replace("/");
  }
  res = await res.json();
  if (res.success) {
    setTimeout(() => {
      toast.update(toastid, {
        render: "Saved",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }, 500);
    Get(id, setValues);
  } else {
    setTimeout(() => {
      toast.update(toastid, {
        render: res.response,
        type: "error",
        isLoading: false,
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }, 500);
  }
};

const UpdateQty = async (id, qty) => {
  let res = await fetch(ApiUrl.Admin_Cards + "/qty/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + User.token,
    },
    body: JSON.stringify({ qty: qty }),
  });

  if (res.status > 400 && res.status < 410) {
    localStorage.removeItem("user-info");
    document.location.replace("/");
  }

  res = await res.json();

  if (res.success) {
    return true;
  } else {
    return false;
  }
};

export default { UploadImage, Get, Put, UpdateQty };
