import ApiUrl from "../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = async (setMembers,setSocial,setRate,setActivities)=>{
    let res = await fetch(ApiUrl.Admin_Dashboard, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    if(res.status > 400 && res.status < 410){
        localStorage.removeItem('user-info');
        document.location.replace('/');
    }
    res = await res.json();
    setMembers(res.members.reverse());
    setSocial(res.social.reverse());
    setRate(res.rate);
    setActivities(res.activities);
}


export default {Get}