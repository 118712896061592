import React from 'react'
import { Outlet } from 'react-router-dom'
import BgImage from '../../images/6.png'
import Logo from '../../images/logo.webp'
import './index.css'

const Index = () => {
  return (
    <div className='airiosoft_layout_wrapper' style={{backgroundImage:`url('${BgImage}')`}}>
      <div className='airiosoft_layout_container'>
        <div className='airiosoft_layout_logo'>
          <img src={Logo}/>
        </div>
        <Outlet/>
      </div>
    </div>
  )
}

export default Index
