import React, { useEffect,useState } from 'react'
import Button from '../../../../components/button'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import './index.css'
import Functions from './function'
import Form from './form'
import NumberInputPopup from '../../../../components/numberInputPopup'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
    const [popform,setPopForm]= useState(false);
    const [pop,setPop]= useState(false);
    const [poped,setPopED]= useState(false);
    const [id,setID]= useState(1001);
    const [List,setList]= useState([]);
    useEffect(()=>{
        Functions.Get(setList);
    },[])
  return (
    <div className='admin_points_activity_container'>
      <div className='admin_points_activity_button_row'>
        <div className='admin_points_activity_col'>
          <Button Value={'Create'} Action={()=>{setPopForm(true)}}/>
        </div>
      </div>
      <div className='admin_members_table'>
          <div className='admin_members_row'>
              <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                  <p>Name</p>
              </div>
              <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                  <p>Date</p>
              </div>
              <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                  <p>Expiration date</p>
              </div>
              <div className='admin_members_col admin_members_th' style={{width:"10%"}}>
                  <p>Points</p>
              </div>
              <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                  <p>Card</p>
              </div>
              <div className='admin_members_col admin_members_th' style={{width:"10%"}}>
                  <p></p>
              </div>
          </div>
          {
              List.map((item,index)=>{
                  return(
                      <div className='admin_members_row' key={index}>
                          <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                              <p>{item.name}</p>
                          </div>
                          <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                              <p>{item.createdAt?.slice(0,10)}</p>
                          </div>
                          <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                              <p><FontAwesomeIcon icon={faPenToSquare} style={{cursor:"pointer"}} onClick={()=>{
                                setPopED(true);
                                setID(item.id);
                              }}/> {item.edate.slice(0, 10)}</p>
                          </div>
                          <div className='admin_members_col admin_members_td' style={{width:"10%"}}>
                              <p><FontAwesomeIcon icon={faPenToSquare} onClick={()=>{
                                setPop(true);
                                setID(item.id);
                              }}/> {item.points}</p>
                          </div>
                          <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                              <p><FontAwesomeIcon icon={faCertificate}/> <a href={'activity/card/'+item.id} target='_blank'>Make a card</a></p>
                          </div>
                          <div className='admin_members_col admin_members_td' style={{width:"10%",display:'flex',justifyContent:'flex-end', padding:"0px 5px"}}>
                              <Button Value={'Delete'} Action={()=>{
                                if(window.confirm("Are you sure you want to delete.")){
                                    Functions.Delete(item.id,toast,setList);
                                }
                              }}/>
                          </div>
                      </div>
                  )
              })
          }
      </div>
      {popform &&
        <Form setPop={setPopForm} toast={toast} setValues={setList}/>
      }
      {pop &&
        <NumberInputPopup setPop={setPop} Function={(cbvalue)=>{
            for (let index = 0; index < List.length; index++) {
                const element = List[index];
                if(element.id == id){
                    Functions.Put(toast,id,{
                        points: cbvalue,
                    },setList);
                    setPop(false);
                }
            }
        }}/>
      }
      {poped &&
        <NumberInputPopup setPop={setPopED} Type='date' Function={(cbvalue)=>{
            for (let index = 0; index < List.length; index++) {
                const element = List[index];
                if(element.id == id){
                    Functions.Put(toast,id,{
                        name: element.name,
                        points: element.points,
                        edate: cbvalue,
                        date: element.date
                    },setList);
                    setPopED(false);
                }
            }
        }}/>
      }
      <ToastContainer/>
    </div>
  )
}

export default Index
