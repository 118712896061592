import React, { useState } from 'react'
import Input from '../../../components/input'
import Button from '../../../components/button'
import './index.css'
import Functions from './function'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [password, setPassword] = useState('');
  const [opassword, setOPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  return (
    <div className='admin_settings_container'>
      <div className='admin_settings_form'>
        <Input Lable={'Old password'} Placeholder={'Enter your old password'} Type={'password'} Value={opassword} setValue={setOPassword} readonly={false}/>
        <Input Lable={'New password'} Placeholder={'Enter your new password'} Type={'password'} Value={password} setValue={setPassword} readonly={false}/>
        <Input Lable={'Confirm password'} Placeholder={'Re-Enter your new password'} Type={'password'} Value={cpassword} setValue={setCPassword} readonly={false}/>
        <Button Value={'Change'} Action={()=>{
          const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_RIGHT});
          Functions.CheckPassword(opassword,(check)=>{
            if(check){
              if(password == cpassword){
                Functions.UpdatePassword(password,(status)=>{
                  if(status){
                    toast.update(toastid, { render: "Saved", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_RIGHT });
                    
                  }
                  else{
                    toast.update(toastid, { render: "Api Failed", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_RIGHT });
                  }
                })
              }
              else{
                toast.update(toastid, { render: "New passwords doesn't match", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_RIGHT });
              }
            }
            else{
              toast.update(toastid, { render: "Old password is incorrect", type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_RIGHT });
            }
          })
        }}/>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Index
