import React from 'react'

const Index = () => {
  return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100vh"}}>
      <h1 style={{fontSize:"40px", fontWeight:"500"}}>404 Not found</h1>
    </div>
  )
}

export default Index
