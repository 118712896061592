import React, { useEffect, useState } from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Function from './function'
import Export from '../../../configTools/allexport'
import Button from '../../../components/button'
import ButtonAlt from '../../../components/buttonalt'
import NumberInputPopup from '../../../components/numberInputPopup'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
    const [List,setList] = useState([]);
    const [filterList,setfilterList] = useState(List);
    const [pop, setPop] = useState(false);
    const [currentId, setCurrentId] = useState(0);
    function filterFunction(value){
        if(value === ""){
        setfilterList(List);
        }
        else{
        const filtedList = List.filter((obj)=>{
            return Object.values(obj)
            .join(" ").toLowerCase()
            .includes(value.toLowerCase());
        })
        setfilterList(filtedList);
        }
    }
    useEffect(()=>{
        setfilterList(List);
    },[List])
    useEffect(()=>{
        Function.Get(setList);
    },[])

    function onSendPoints(id){
        setCurrentId(id);
        setPop(true);
    }

    function onDelete(id){
        // Alert

        if (!window.confirm('Are you sure you want to delete this member?')) {
            return;
        }
        const success = Function.Delete(id);

        if (success) {
            toast.success('Member deleted successfully');
            setList(List.filter((item) => item.id !== id));
        } else {
            toast.error('Failed to delete member');
        }
    }

    async function setPoints(value){
        const success = await Function.SetPoints(currentId, value);

        if (success) {
            toast.success('Points sent successfully');
        } else {
            toast.error('Failed to send points');
        }

        setPop(false);
    }
    return (
        <div className='admin_members_container'>
            <div className='admin_members_searchbox'>
                <div style={{display:"flex"}}>
                    <div style={{marginRight:"10px"}}>
                        <Button Value={'Export XLSX'}  Action={()=>{
                            var sendData = [];
                            for (let index = 0; index < List.length; index++) {
                                const element = List[index];
                                sendData.push({
                                    "Id":element.id,
                                    "Surname":element.lastname,
                                    "First name":element.firstname,
                                    "Points":element.totalpoints,
                                    "Phone":element.phone,
                                    "Email":element.email,
                                    "Address":element.address1+" "+element.address2+" "+element.city+" "+element.state+" "+element.country+" "+element.postalcode,
                                    "Date of birth": element.year + "-" + element.month + "-" + element.day,
                                    "Username": element.username
                                })
                            }
                            Export.xlsxExport(sendData,"MemebersDataXLSX")
                        }}/>
                    </div>
                    {/*
                        <div style={{marginRight:"10px"}}>
                            <ButtonAlt Value={'Export CSV'} Action={()=>{
                                let headers = ['Id,Surname,First name,Points,Phone,Email,Address,Date of birth,Username'];
                                let usersCsv = List.reduce((acc, member) => {
                                    const { id, lastname, firstname, phone, email, address1, address2, city, state, country, postalcode, year, month, day, username, totalpoints } = member
                                    acc.push([id, lastname.split(',').join(''), firstname.split(',').join(''), totalpoints.split(',').join(''), phone.split(',').join(''), email.split(',').join(''), address1.split(',').join('')+" "+address2.split(',').join('')+" "+city.split(',').join('')+" "+state.split(',').join('')+" "+country.split(',').join('')+" "+postalcode.split(',').join('')+" "+(year+"-"+month+"-"+day)+" "+username.split(',').join('')].join(','))
                                    return acc
                                }, [])
                                Export.downloadExport({
                                    data: [...headers, ...usersCsv].join('\n'),
                                    fileName: 'MemebersDataCSV.csv',
                                    fileType: 'text/csv',
                                })
                            }}/>
                        </div>
                     */}
                    
                    <div style={{marginRight:"10px"}}>
                        <ButtonAlt Value={'Export Json'}  Action={()=>{
                            var sendData = [];
                            for (let index = 0; index < List.length; index++) {
                                const element = List[index];
                                sendData.push({
                                    "Id":element.id,
                                    "Surname":element.lastname,
                                    "First name":element.firstname,
                                    "Points":element.totalpoints,
                                    "Phone":element.phone,
                                    "Email":element.email,
                                    "Address":element.address1+" "+element.address2+" "+element.city+" "+element.state+" "+element.country+" "+element.postalcode,
                                    "Date of birth": element.year + "-" + element.month + "-" + element.day,
                                    "Username": element.username
                                })
                            }
                            Export.downloadExport({
                                data: JSON.stringify(sendData),
                                fileName: 'MemebersDataJSON.json',
                                fileType: 'text/json',
                            })
                        }}/>
                    </div>
                </div>
                <div className='admin_members_searchinput'>
                    <input type='text' placeholder='Search' onChange={(e)=>{filterFunction(e.target.value);}}/>
                    <button>
                        <FontAwesomeIcon icon={faSearch}/>
                    </button>
                </div>
            </div>
            <div className='admin_members_table'>
                <div className='admin_members_row'>
                    <div className='admin_members_col admin_members_th' style={{width:"5%"}}>
                        <p>Id</p>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"10%"}}>
                        <p>Surname</p>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"10%"}}>
                        <p>First name</p>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"5%"}}>
                        <p>Points</p>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"10%"}}>
                        <p>Phone</p>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"15%"}}>
                        <p>Email</p>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                        <p>Address</p>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"5%"}}>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"10%"}}>
                    </div>
                    <div className='admin_members_col admin_members_th' style={{width:"10%"}}>
                    </div>
                </div>
                {filterList.length > 0 &&
                    filterList.map((item,index)=>{
                        return(
                            <div className='admin_members_row' key={index}>
                                <div className='admin_members_col admin_members_td' style={{width:"5%"}}>
                                    <p>{item.id}</p>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"10%"}}>
                                    <p>{item.lastname}</p>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"10%"}}>
                                    <p>{item.firstname}</p>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"5%"}}>
                                    <p>{item.totalpoints}</p>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"10%"}}>
                                    <p>{item.phone}</p>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"15%"}}>
                                    <p>{item.email}</p>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                                    <p>{item.address1} {item.address2} {item.city} {item.state} {item.country} {item.postalcode}</p>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"5%"}}>
                                    <p><a href={'members/'+item.id}>View</a></p>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"10%"}}>
                                    <ButtonAlt Value="Send points" Action={() => onSendPoints(item.id)}></ButtonAlt>
                                </div>
                                <div className='admin_members_col admin_members_td' style={{width:"10%"}}>
                                    <ButtonAlt Value="Delete" Action={() => onDelete(item.id)}></ButtonAlt>
                                </div>
                            </div>
                        )
                    })
                }
                {filterList.length === 0 &&
                    <div className='admin_members_row'>
                        <div className='admin_members_col admin_members_td' style={{width:"100%"}}>
                            <p style={{textAlign:"center"}}>No data found</p>
                        </div>
                    </div>
                }
            </div>
            {pop && <NumberInputPopup setPop={setPop} Function={setPoints} />}
            <ToastContainer/>
        </div>
    )
}

export default Index
