import React, { useEffect, useState } from 'react'
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import './index.css'
import Functions from './function'
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [value,setValue]= useState(0);
  useEffect(()=>{
    Functions.Get(setValue);
  },[])
  return (
    <div className='admin_exchangerate_container'>
      <div className='admin_exchangerate_form'>
        <Input Lable={'1 currency = number of points'} Placeholder={'Enter value'} Type={'text'} Value={value} setValue={setValue} readonly={false}/>
        <Button Value={'Save'} Action={()=>{
          Functions.Post(toast,{rate: value},setValue);
        }}/>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Index
