import React, { useEffect, useState } from 'react'
import ButtonAlt from '../../../components/buttonalt'
import './index.css'
import Functions from './function'

const Index = () => {
  const [Memeber, setMembers]= useState([]);
  const [Social, setSocial]= useState([]);
  const [rate, setRate]= useState(0);
  const [activities, setActivities]= useState(0);
  useEffect(()=>{
    Functions.Get(setMembers,setSocial,setRate,setActivities);
  },[])
  return (
    <div className='airiosoft_admin_dashboard'>
      <div className='airiosoft_admin_boxes_row'>
        <div className='airiosoft_admin_boxes_col'>
          <div className='airiosoft_admin_boxes_container'>
            <h3>Memebers</h3>
            <p>{Memeber.length}</p>
            <ButtonAlt Value={'View'} Action={()=>{
              window.location.replace('/admin/members');
            }}/>
          </div>
        </div>
        <div className='airiosoft_admin_boxes_col'>
          <div className='airiosoft_admin_boxes_container'>
            <h3>Exchange rate</h3>
            <p>{rate}</p>
            <ButtonAlt Value={'View'} Action={()=>{
              window.location.replace('/admin/pointsettings/exchange');
            }}/>
          </div>
        </div>
        <div className='airiosoft_admin_boxes_col'>
          <div className='airiosoft_admin_boxes_container'>
            <h3>Activities</h3>
            <p>{activities}</p>
            <ButtonAlt Value={'View'} Action={()=>{
              window.location.replace('/admin/pointsettings/activity');
            }}/>
          </div>
        </div>
      </div>
      <div className='airiosoft_admin_tables_row'>
        <div className='airiosoft_admin_tables_col60'>
          <h3>Recent Members</h3>
          <div className='admin_members_table'>
            <div className='admin_members_row'>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Id</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"30%"}}>
                    <p>Surname</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"30%"}}>
                    <p>First name</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"20%"}}>
                    <p>Points</p>
                </div>
            </div>
            {Memeber.length > 0 &&
                Memeber.slice(0, 5).map((item,index)=>{
                    return(
                        <div className='admin_members_row' key={index}>
                            <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                                <p>{item.id}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                                <p>{item.lastname}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"30%"}}>
                                <p>{item.firstname}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"20%"}}>
                                <p>{item.totalpoints}</p>
                            </div>
                        </div>
                    )
                })
            }
            {Memeber.length == 0 &&
                <div className='admin_members_row'>
                    <div className='admin_members_col admin_members_td' style={{width:"100%"}}>
                        <p style={{textAlign:"center"}}>No data found</p>
                    </div>
                </div>
            }
          </div>
        </div>
        <div className='airiosoft_admin_tables_col40'>
          <h3>Recent Social Requests</h3>
          <div className='admin_members_table'>
            <div className='admin_members_row'>
                <div className='admin_members_col admin_members_th' style={{width:"40%"}}>
                    <p>Title</p>
                </div>
                <div className='admin_members_col admin_members_th' style={{width:"60%"}}>
                    <p>Description</p>
                </div>
            </div>
            {Social.length > 0 &&
                Social.slice(0, 5).map((item,index)=>{
                    return(
                        <div className='admin_members_row' key={index}>
                            <div className='admin_members_col admin_members_td' style={{width:"40%"}}>
                                <p>{item.title}</p>
                            </div>
                            <div className='admin_members_col admin_members_td' style={{width:"60%"}}>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    )
                })
            }
            {Social.length == 0 &&
                <div className='admin_members_row'>
                    <div className='admin_members_col admin_members_td' style={{width:"100%"}}>
                        <p style={{textAlign:"center"}}>No data found</p>
                    </div>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
