import React,{useState} from 'react'
import './index.css'
import Input from '../../../components/input'
import Button from '../../../components/button'
import Function from './function'

const Form = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  return (
    <div className='airiosoft_loginpage_wrapper'>
      <div className='airiosoft_loginpage_container'>
          <h1>Welcome back</h1>
          <div className='airiosoft_loginpage_form'>
              <Input Lable={'Email / Username'} Placeholder={'Enter your email'} Type={'text'} Value={email} setValue={setEmail} readonly={false}/>
              <Input Lable={'Password'} Placeholder={'Enter your password'} Type={'password'} Value={password} setValue={setPassword} readonly={false}/>
              <Button Value={'Login'} Action={()=>{
                Function.Submit({
                  username: email,
                  password: password
                })
              }}/>
        </div>
      </div>
    </div>
  )
}

export default Form
