import ApiUrl from "../../../../configTools/api";
const User = JSON.parse(localStorage.getItem("user-info"));

const Get = async (setValues)=>{
    let res = await fetch(ApiUrl.Admin_Pointsdata+"/general", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        }
    })
    if(res.status > 400 && res.status < 410){
        localStorage.removeItem('user-info');
        document.location.replace('/');
    }
    res = await res.json();
    if(res.success){
        setValues(res.response);
    }
    else{
        alert(res.response);
    }
}

const Put = async (toast,id,body,setValues)=>{
    const toastid = toast.loading("Please wait ...",{ position: toast.POSITION.BOTTOM_RIGHT});
    let res = await fetch(ApiUrl.Admin_Pointsdata+"/general/"+id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+User.token
        },
        body: JSON.stringify(body)
    });
    if(res.status > 400 && res.status < 410){
        localStorage.removeItem('user-info');
        document.location.replace('/');
    }
    res = await res.json();
    console.log(res);
    if(res.success){
        setTimeout(() => {
          toast.update(toastid, { render: "Saved", type: "success", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_RIGHT });
        }, 500);
        Get(setValues);
    }
    else{
        setTimeout(() => {
          toast.update(toastid, { render: res.response, type: "error", isLoading: false, autoClose:2000, position: toast.POSITION.BOTTOM_RIGHT });
        }, 500);
    }
}


export default {Get,Put}