import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import Input from '../input'
import Button from '../button'

const Index = ({setPop,Function, Type = 'number'}) => {
    const [Value, setValue] = useState(0);
  return (
    <div className='airiosoft_nip_popup_wrapper'>
      <div className='airiosoft_nip_popup_container'>
        <div className='airiosoft_nip_popup_cross'>
            <button onClick={()=>{
                setPop(false);
            }}><FontAwesomeIcon icon={faXmark}/></button>
        </div>
        <div className='airiosoft_nip_popup_form'>
            <Input Lable={'Value'} Placeholder={'Enter your value'} Type={Type} Value={Value} setValue={setValue} readonly={false}/>
            <Button Value={'Save'} Action={()=>{
                Function(Value);
            }}/>
        </div>
      </div>
    </div>
  )
}

export default Index
